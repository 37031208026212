import { Box, SystemProps, Text } from "@storyofams/react-ui";

import Link from "next/link";
import { usePostHog } from "posthog-js/react";
import { Stroke } from "../components/Stroke";

type VideowiseProps = {
  content: {
    snippet?: string;
    title: string;
    description: string;
  };
} & SystemProps;
const snippet =
  '<div class="reeview-app-widget" id="reeview-app-widget_671a7388fef448002a0b2f83"></div>';
export const Videowise = ({ content, ...props }: VideowiseProps) => {
  return (
    <Box
      alignItems={"center"}
      width={"100%"}
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
    >
      <Box position={"relative"} width={"180px"} textAlign={"center"}>
        <Stroke color={"#EEE3D7"} top={[-0.5, -1]} />
        <Link
          href={
            "https://www.instagram.com/naturalheroes?igsh=MTd1dzBnNDdudWY5cg=="
          }
          target="_blank"
        >
          <Text
            mb={1}
            fontWeight={500}
            fontSize={"18px"}
            cursor={"pointer"}
            lineHeight={"25.2px"}
            position={"relative"}
            zIndex={1}
          >
            @naturalheroes
          </Text>
        </Link>
      </Box>

      <Text
        fontFamily={"DINPro"}
        fontWeight={700}
        fontSize={["36px", "40px"]}
        lineHeight={["43.2px", "48px"]}
        mb={1}
      >
        {content.title}
      </Text>
      <Text textAlign={"center"} fontSize={"18px"} lineHeight={"25.2px"}>
        {content.description}
      </Text>
      <Box
        mt="32px"
        width={"100%"}
        height={"100%"}
        // onClick={() => posthog.capture("Clicked Greenspark Homepage")}
        {...props}
        dangerouslySetInnerHTML={{
          __html: content?.snippet ? content.snippet : snippet,
        }}
      />
    </Box>
  );
};
